.main {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    position: relative;
}

.inner_main {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: calc(100% - 75px);
    position: relative;
}

.nav {
    width: 300px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    transition: 0.3s;
    overflow: hidden;
}

.nav_inner {
    width: 300px;
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.closed {
    width: 0 !important;
}

.body {
    background-color: #F5F5F5;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    max-width: 100%;
    width: calc(100% - 300px);
    transition: 0.3s;
    flex-direction: column;
    height: 100%;
    -webkit-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.2) inset;
    -moz-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.2) inset;
}
.body2 {
    background-color: #F5F5F5;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    max-width: 100%;
    width: 100%;
    transition: 0.3s;
    flex-direction: column;
    height: 100%;
    /*-webkit-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.2) inset;*/
    /*-moz-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.2) inset;*/
    /*box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.2) inset;*/
}
.bodyMax {
    width: 100% !important;
}

.inner_body {
    width: 100%;
    position: relative;
}

.form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: 15px;
    flex-direction: column;
}

.formInputBlock {
    width: 100%;
    margin: 0;
    padding: 0;
}

.formInput {
    font-size: 1rem;
    padding: 10px 15px;
    font-weight: 400 !important;
}
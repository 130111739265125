.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 2px;
    font-size: 0.7rem;
    min-width: 2300px;
}
.th, .td {
    border: 1px solid #FFFFFF;
    text-align: center;
    border-radius: 8px;
}
.th {
    background-color: #2162F4;
    color: white;
    padding: 10px;
}
.td {
    background-color: #E2E2E2;
    font-size: 0.8rem !important;
}
.td * {
    font-size: 0.8rem !important;
}
.tr:last-child .td {
    border-bottom: 2px solid #FFFFFF;
}
.blueBtn {
    /*background-color: #153C6B !important;*/
    color: white !important;
    border: none;
    height: auto;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: linear-gradient(30deg, #0059ff, #4ca2f7) !important;
    background-size: 100% auto;
    font-family: inherit;
    padding: 0.6em 1em;
}

.greyBtn {
    /*background-color: #153C6B !important;*/
    color: white !important;
    border: none;
    height: auto;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: linear-gradient(30deg, #444444, #949494) !important;
    background-size: 100% auto;
    font-family: inherit;
    padding: 0.6em 1em;
}

.greyBtn[disabled] {
    background-image: linear-gradient(30deg, #a8a8a8, #a8a8a8) !important;
}
.blueBtn[disabled] {
    background-image: linear-gradient(30deg, #a8a8a8, #a8a8a8) !important;
}

.orangeBtn {
    /*background-color: #153C6B !important;*/
    color: white !important;
    border: none;
    height: auto;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: linear-gradient(30deg, #e07912, #fa952d) !important;
    background-size: 100% auto;
    font-family: inherit;
    padding: 0.6em 1em;
}

.orangeBtn[disabled] {
    background-image: linear-gradient(30deg, #a8a8a8, #a8a8a8) !important;
}

.greenBtn {
    /*background-color: #153C6B !important;*/
    color: white !important;
    border: none;
    height: auto;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: linear-gradient(30deg, #1fa281, #008464) !important;
    background-size: 100% auto;
    font-family: inherit;
    padding: 0.6em 1em;
}

.greenBtn[disabled] {
    background-image: linear-gradient(30deg, #a8a8a8, #a8a8a8) !important;
}

.defaultButton {
    color: black !important;
    border: none;
    height: auto;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: linear-gradient(30deg, #e1e1e1, #d1e3ff) !important;
    background-size: 100% auto;
    font-family: inherit;
    padding: 0.6em 1em;
}
